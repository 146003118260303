@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom styles */
body {
  font-family: 'Roboto', sans-serif; /* Apply the Google Font */
}
