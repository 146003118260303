body {
  font-family: 'Roboto', sans-serif;
  background-color: #1a202c;
  color: white;
  margin: 0;
  padding: 0;
  font-size: 14px; /* Base font size for mobile */
}

.container {
  background-color: transparent;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  max-width: 800px; /* Adjusted padding and max-width */
}

@media (min-width: 768px) {
  .container {
    padding: 0 30px;
  }

  body {
    font-size: 16px; /* Base font size for tablets */
  }
}

@media (min-width: 1024px) {
  body {
    font-size: 18px; /* Base font size for desktops */
  }
}

.header {
  background-color: #1a202c;
  color: white;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  padding: 0.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease, box-shadow 0.3s ease;
}

button:focus {
  outline: 2px solid #D97706; /* Add a focus outline for better accessibility */
  outline-offset: 2px;
}

button:disabled {
  background-color: #ccc;
}

.chat-box {
  display: flex;
  flex-direction: column; /* Ensures messages stack vertically */
  overflow-y: auto;
  flex-grow: 1;
  background-color: transparent;
  margin-top: 3.0rem; /* Ensure messages are not hidden behind the fixed header */
  padding-top: 3.0rem; /* Ensure messages are not hidden behind the fixed header */
  padding-bottom: 7rem; /* Ensure messages are not hidden behind the input box */
  position: relative; /* Ensure positioning of child elements */
}

.block-height-price-background {
  position: absolute;
  top: 70%; /* Adjust the top position as needed */
  left: 50%;
  transform: translateX(-50%);
  color: #4a5568;
  font-size: 0.625rem; /* Same as text-xxs */
  text-align: center;
  z-index: 1; /* Ensure it's above other content */
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
}

.message-bubble {
  display: flex;
  align-items: flex-start; /* Align items at the top */
  padding: 1rem;
  border-radius: 1rem;
  max-width: 90%;
  transition: all 0.3s ease;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
  margin: 0.5rem 0;
  position: relative;
  font-size: 14px; /* Default font size */
  word-wrap: break-word; /* Ensure long words break to avoid overflow */
}

.message-bubble.user-message {
  background: linear-gradient(135deg, #D97706, #C05621); /* Darker orange gradient */
  color: white;
  align-self: flex-end;
  margin-left: auto;
  text-align: right;
  border-bottom-right-radius: 0.25rem;
}

.message-bubble.assistant-message {
  background-color: #4a5568;
  color: white;
  align-self: flex-start;
  margin-right: auto;
  border-bottom-left-radius: 0.25rem;
  display: flex;
  align-items: flex-start;
}

.message-bubble.assistant-message.has-profile-pic {
  padding-left: 0.5rem; /* Add space for profile picture */
}

.profile-pic {
  width: 40px; /* Adjusted width */
  height: 40px; /* Adjusted height */
  border-radius: 50%; /* Make the image round */
  margin-right: 0.5rem; /* Space between image and text */
  object-fit: cover; /* Ensure the image covers the entire area */
}

.message-content {
  flex-grow: 1;
}

.message-bubble ul {
  padding-left: 1.5em;
  list-style-type: disc;
}

.message-bubble ol {
  padding-left: 1.5em;
  list-style-type: decimal;
}

.input-box-wrapper {
  background-color: #1a202c;
  z-index: 20;
}

.input-box {
  display: flex;
  align-items: center;
  background-color: #2d3748;
  margin: 0 auto;
  border-radius: 9999px;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  overflow: hidden;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  max-width: 100%; /* Ensure the input box scales down */
}

.input-box:focus-within {
  border-color: #D97706;
  box-shadow: 0 0 0 3px rgba(217, 119, 6, 0.0); /* 3px in rem units */
}

input[type="text"] {
  flex-grow: 1;
  padding: 10px 15px; /* Adjusted padding */
  border: none;
  border-radius: 9999px;
  background-color: #4a5568;
  color: white;
  resize: none;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-size: 14px; /* Default font size */
  margin-right: 0.5rem; /* Add some space between the input and the button */
}

button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px; /* General border-radius for all buttons */
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease, box-shadow 0.3s ease;
}

button:focus {
  outline: 2px solid #D97706; /* Add a focus outline for better accessibility */
  outline-offset: 2px;
}

button:disabled {
  background-color: #ccc;
}

/* Specific styles for the submit button */
.submit-button {
  width: 40px; /* Adjusted width */
  height: 40px; /* Adjusted height */
  background: linear-gradient(135deg, #D97706, #C05621); /* Darker orange gradient */
  border-radius: 50%; /* Rounded button */
  transform: rotate(-90deg); /* Rotate the button to make the arrow point up */
}

.submit-button:hover {
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
}

.submit-button:disabled {
  background-color: #ccc;
}

/* Add styles for other buttons (e.g., menu and language switch) */
.menu-button, .language-button {
  background-color: #2d3748; /* Background color for other buttons */
  padding: 10px 20px; /* Add some padding */
  border-radius: 5px; /* Rounded corners */
}

.menu-button:hover, .language-button:hover {
  background-color: #4a5568; /* Hover effect for other buttons */
}

.logo {
  height: 40px; /* Adjusted height */
  width: auto;
  margin-bottom: 10px; /* Adjusted margin */
}

.slogan {
  margin-bottom: 1rem;
}

.profile-pic {
  width: 40px; /* Adjusted width */
  height: 40px; /* Adjusted height */
}

@media (min-width: 768px) {
  .message-bubble {
    font-size: 15px; /* Adjusted font size */
  }

  input[type="text"] {
    font-size: 15px; /* Adjusted font size */
  }

  .logo {
    height: 60px; /* Adjusted height */
  }

  .header {
    padding: 1rem 2rem;
  }
}

@media (min-width: 1024px) {
  .message-bubble {
    font-size: 16px; /* Adjusted font size */
  }

  input[type="text"] {
    font-size: 16px; /* Adjusted font size */
  }
}

.mt-header {
  margin-top: 5rem; /* Adjust according to your header height */
}

/* AuthPage specific styles */
.auth-page {
  background-color: #1a202c; /* Matching the main page background color */
  display: flex;
  flex-direction: column; /* Stack items vertically on mobile */
}

.auth-container {
  position: relative;
  background-color: #2d3748;
  z-index: 1;
  overflow: hidden; /* Ensure the logo doesn't spill out of the container */
}

.auth-container img {
  position: absolute;
  top: -50px; /* Move the logo up */
  left: 50%;
  transform: translateX(-50%) scale(1.5); /* Increase the scale for zoom-in effect */
  width: 200px; /* Adjusted width */
  height: 200px; /* Adjusted height */
  opacity: 0.1;
  z-index: 0;
}

.auth-container h2,
.auth-container input,
.auth-container button {
  position: relative;
  z-index: 2;
}

.auth-input {
  background-color: #4a5568;
  color: white;
}

.auth-button {
  background: linear-gradient(135deg, #D97706, #C05621); /* Darker orange gradient */
  color: white;
}

.auth-button:hover {
  background-color: #C05621;
  color: white;
}

.auth-button-google {
  background-color: #4a5568;
  color: white;
}

.auth-link {
  color: white; /* Override global button styles */
  text-decoration: underline; /* Add underline for links */
  cursor: pointer;
  background: none; /* Remove button background */
  border: none; /* Remove button border */
  width: auto; /* Reset width */
  height: auto; /* Reset height */
  display: inline; /* Reset display */
  padding: 0; /* Remove padding */
}

.chat-box-auth {
  position: absolute;
  width: 100%;
  max-width: 800px; /* Ensure the chat box has the same max width */
  margin: 0 auto;
  top: 0;
  bottom: 0;
  z-index: 0; /* Ensure the chat box is behind the auth container */
  opacity: 0.1; /* Slightly visible for background effect */
}

.message-bubble-auth {
  padding: 1rem;
  border-radius: 1rem;
  max-width: 80%;
  transition: all 0.3s ease;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
  margin: 0.5rem 0;
  position: relative;
  font-size: 14px; /* Default font size */
  opacity: 0.7; /* Reduced opacity for background effect */
}

.user-message-auth {
  background: linear-gradient(135deg, #D97706, #C05621); /* Darker orange gradient */
  color: white;
  align-self: flex-end;
  margin-left: auto;
  text-align: right;
  border-bottom-right-radius: 0.25rem;
}

.assistant-message-auth {
  background-color: #4a5568;
  color: white;
  align-self: flex-start;
  margin-right: auto;
  border-bottom-left-radius: 0.25rem;
}

.text-xxs {
  font-size: 0.625rem; /* 10px */
  color: #4a5568;
}

@media (min-width: 768px) {
  .auth-page {
    flex-direction: row; /* Display items side by side on larger screens */
  }

  .auth-container {
    order: 2; /* Display the auth container on the right */
    width: 50%; /* Adjust width */
  }

  .chat-box-auth {
    order: 1; /* Display the chat box on the left */
    display: flex; /* Ensure chat box is visible on larger screens */
    flex-direction: column;
    justify-content: center;
    width: 50%; /* Adjust width */
  }

  .hidden {
    display: none;
  }
}
